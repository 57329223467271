<template>
  
                <a href="http://track.khairat.com?bid=24&cust_id=2636">
                    <img src='https://admin.khairatlebanon.com/assets/images/uploads/productimages/Product20210927T125748.jpg' style='width:66px;height:78px'/>
                </a>
            
</template>

<script>
export default {
  name:"ShareBanner"
}
</script>

<style>

</style>